import React, { useEffect } from "react";
import { initializeScrollAnimation } from "../elements_pages/ScrollPage";

function Langage() {
  useEffect(() => {
    const cleanupScrollAnimation = initializeScrollAnimation();

    // Retirez l'écouteur d'événement lorsque le composant est démonté
    return cleanupScrollAnimation;
  });
  return (
    <div>
      <h2>Mes Langages de Programmation</h2>
      <div className="scroll-item">
        À ce jour j'ai appris énormément de choses concernant la programmation
        web, en travaillant seul ou même en groupe de façon présentielle ou bien
        à distance.
      </div>
      <div className="scroll-item">
        <b>HTML </b>
        <i className="fa-brands fa-html5 fa-lg html"></i> / <b>CSS </b>
        <i className="fa-brands fa-css3-alt fa-lg css"></i>
        <br />
        J'ai commencé par étudier les bases de codage en utilisant le HTML pour
        structurer ma page web ainsi que le Css pour la partie design et
        agencement d'une page web.
      </div>
      <div className="scroll-item">
        <b>PHP </b>
        <i className="fa-brands fa-php fa-lg php"></i>
        <br />
        Ensuite, j'ai travaillé avec PHP, un langage de programmation back-end
        qui permet d'interagir avec le serveur pour utiliser les bases de
        données. J'ai commencé par faire des requêtes via PDO pour récupérer et
        stocker les données.
      </div>
      <div className="scroll-item">
        <b>JAVASCRIPT </b>
        <i className="fa-brands fa-js fa-lg js"></i>
        <br />
        JavaScript est un langage front-end. Son principal avantage est
        d'éliminer le rechargement de page présent dans PHP. Avec ce langage,
        j'ai utilisé les outils fournis par les différents navigateurs, tels que
        la Console, le Network, etc. JavaScript permet de créer des événements
        dynamiques sur les pages web.
        <br />
        Ajax m'a permis de faire des appels à des API afin de concevoir des
        sites capables de récupérer la météo pour n'importe quelle ville en
        France à une plage horaire donnée, ou encore d'afficher une carte de la
        France avec des lieux touristiques à découvrir. Ensuite, j'ai eu une
        initiation légère à jQuery.
      </div>
      <div className="scroll-item">
        <b>
          BASE DE DONNEES{" "}
          <img
            src="https://img.icons8.com/glyph-neue/30/000000/sql.png"
            alt="SQL"
          />{" "}
          / NoSQL
        </b>
        <br />
        Les bases de données jouent un rôle primordial dans le stockage des
        données utilisateur, ainsi que pour conserver des informations telles
        que des images ou les données d'un formulaire. Pour cela, j'ai utilisé
        MySQL via PhpMyAdmin pour créer des tables. Cela m'a permis d'exécuter
        des requêtes SQL, que ce soit pour effectuer des SELECT, des INSERT, des
        UPDATE ou des DELETE.
        <br />
        Dans mon cas, j'ai développé une application de listes avec une
        fonctionnalité de connexion utilisateur. Pour cela, j'ai utilisé une
        base de données NoSQL via MongoDB afin de stocker les données et de
        faire évoluer votre base. Cette approche était adaptée à mon projet car
        elle offrait la flexibilité nécessaire pour gérer les schémas de données
        évolutifs et pour prendre en charge les charges de travail distribuées.
        Cependant, il est important de noter que l'utilisation de NoSQL peut
        présenter des défis en matière de cohérence et d'intégrité des données,
        en particulier dans des environnements distribués.
      </div>
      <div className="scroll-item">
        <b>SYMFONY </b>
        <i className="fa-brands fa-symfony fa-lg symfony"></i>
        <br />À ce jour, j'ai conçu plusieurs sites web et applications web en
        utilisant ce framework. Mes projets vont de la création de sites
        d'actualités à des applications web intégrant une virtualisation 3D d'un
        terrain. Ce framework offre un environnement propice au développement et
        à la sécurisation des sites grâce à son système de routage. Chaque page
        est gérée par un ou plusieurs contrôleurs, ce qui permet le
        développement de fonctionnalités distinctes dans chacun de ces
        contrôleurs. De plus, la communauté contribue souvent à enrichir ce
        framework en y ajoutant de nouvelles fonctionnalités.
      </div>
      <div className="scroll-item">
        <b>
          NodeJS <i className="fa-brands fa-node fa-lg node"></i> / React{" "}
          <i className="fa-brands fa-react fa-lg react"></i>
        </b>
        <br />
        J'ai commencé à explorer le développement mobile en utilisant React pour
        le Front-End et Node.js pour la partie back-end de mes applications. Des
        géants tels que Netflix et Twitter l'utilisent déjà. Pour moi, ce
        framework représente l'avenir du développement web et mobile grâce à sa
        clarté et son efficacité. Les fonctionnalités sont constamment
        améliorées par une communauté dynamique. Pour votre information, le site
        que vous consultez actuellement a été développé avec React.
      </div>
      <div className="scroll-item">
        <b>
          Drupal <i className="fa-brands fa-drupal fa-lg drupal"></i>
        </b>
        <br />
        Lors de mon stage de fin de mission pour le Bachelor CSI, j'ai eu
        l'opportunité de travailler avec le CMS Drupal sur plusieurs projets
        variés, allant de la création d'une application pour un promoteur
        immobilier à la conception d'un site web pour une mairie. Drupal est un
        système de gestion de contenu (CMS) open-source, largement utilisé pour
        la création de sites web complexes et personnalisables. Il offre une
        grande flexibilité grâce à ses modules extensibles, permettant de
        répondre aux besoins spécifiques de chaque projet tout en garantissant
        une gestion efficace du contenu.
      </div>
    </div>
  );
}

export default Langage;
