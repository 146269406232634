export function initializeScrollAnimation() {
  const elementsToAnimate = document.querySelectorAll(".scroll-item");

  const handleScroll = () => {
    elementsToAnimate.forEach((element) => {
      const elementTop = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementTop < windowHeight * 1) {
        element.classList.add("visible");
      }
    });
  };

  window.addEventListener("scroll", handleScroll);

  // Retirez l'écouteur d'événement lorsque le composant est démonté
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}
