import React from "react";
import Typewriter from "typewriter-effect";
import ImageProfil from "../images/profil.png";
import "../css/HeroBanner.css";

function HeroBanner() {
  return (
    <div className="hero-banner">
      <div className="hero-content-container">
        <div className="hero-content">
          <h1>ALARY Damien</h1>
          <div className="typewriter-text">
            <Typewriter
              options={{
                strings: ["Développeur Informatique"],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
        <div className="profile-image-container">
          <img src={ImageProfil} alt="Mon Profil" />
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
