import React, { useEffect, useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function ProjetModal({ isOpen, onRequestClose, images, captions }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    setCurrentImageIndex(0); // Réinitialisez l'index de l'image après 0 millisecondes (sans transition)
  }, [isOpen]);

  useEffect(() => {
    setIsImageLoaded(true);
  }, [currentImageIndex]);

  const handleNext = () => {
    setIsImageLoaded(false);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 500); // Attendez la fin de la transition (0.5s)
  };

  const handlePrev = () => {
    setIsImageLoaded(false);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }, 500); // Attendez la fin de la transition (0.5s)
  };

  const modalClassName = isImageLoaded ? "fade-in" : "fade-out"; // Appliquez les classes de transition

  // Styles CSS pour centrer la modal
  const modalStyle = {
    content: {
      width: "auto", // Ajustez la largeur automatiquement en fonction de la taille de l'image
      height: "auto", // Ajustez la hauteur automatiquement en fonction de la taille de l'image
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "50px", // Supprimez le padding par défaut
      border: "none", // Supprimez la bordure par défaut
      background: "none", // Supprimez l'arrière-plan par défaut
      position: "relative", // Position relative pour permettre le positionnement absolu des boutons
      overflow: "hidden", // Désactive les barres de défilement
    },
  };

  // Styles CSS pour les boutons
  const buttonContainerStyle = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    width: "100%",
    top: "calc(50% + 20px)",
    left: "0",
    right: "0",
    justifyContent: "space-between",
  };

  const buttonStyle = {
    background: "none",
    border: "none",
    cursor: "pointer",
    margin: "10px", // Espacement horizontal entre les boutons
  };

  const closeButtonStyle = {
    ...buttonStyle,
    position: "absolute",
    top: "0",
    right: "20px",
    margin: "50px 10px 10px 10px",
  };

  const prevButtonStyle = {
    ...buttonStyle,
    position: "absolute",
    top: "50%",
    left: "0",
  };

  const nextButtonStyle = {
    ...buttonStyle,
    position: "absolute",
    top: "50%",
    right: "0",
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Projet Modal"
      style={modalStyle}
    >
      {captions[currentImageIndex] && (
        <h2 className="image-caption">{captions[currentImageIndex]}</h2>
      )}
      <div style={buttonContainerStyle}>
        <button onClick={handlePrev} style={prevButtonStyle}>
          <i className="fa-solid fa-arrow-left fa-2xl"></i>
        </button>

        <button onClick={handleNext} style={nextButtonStyle}>
          <i className="fa-solid fa-arrow-right fa-2xl"></i>
        </button>
      </div>
      <button onClick={onRequestClose} style={closeButtonStyle}>
        <i className="fa-solid fa-xmark fa-2xl"></i>
      </button>
      <div className="projet-detail">
        <img
          src={images[currentImageIndex]}
          alt={`Projet ${currentImageIndex + 1}`}
          className={`project-image ${modalClassName}`} // Appliquez la classe de transition à l'image
        />
      </div>
    </Modal>
  );
}

export default ProjetModal;
