import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./elements_pages/Loader";
import HeroBanner from "./elements_pages/HeroBanner";
import Navbar from "./elements_pages/Navbar";
import Accueil from "./pages/Accueil";
import Projets from "./pages/Projets";
import Cursus from "./pages/Cursus";
import Langage from "./pages/Langage";
import "./css/Icons.css";
import "./css/styles.css";
import Footer from "./elements_pages/Footer";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Mettez fin au chargement après une période
    }, 2500); // 2.5 secondes de délai
  });

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="app">
          <div className="navbar">
            <Navbar />
          </div>
          <HeroBanner />
          <div className="content">
            <Routes>
              <Route path="/" Component={Accueil} />
              <Route path="/projets" Component={Projets} />
              <Route path="/cursus" Component={Cursus} />
              <Route path="/langage" Component={Langage} />
            </Routes>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
