import React, { useState, useEffect } from "react";
import "../css/Loader.css";

function Loader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Mettez fin au chargement après un certain délai
    }, 2000); // 2 secondes de délai
  }, []);
  
  return (
    <div className={`loader-container ${isLoading ? "" : "fade-out"}`}>
      <div className="loader"></div>
    </div>
  );
}

export default Loader;
