import React, { useEffect } from "react";
import { initializeScrollAnimation } from "../elements_pages/ScrollPage";
import CV from "../download/ALARY_Damien_Développeur_Informatique.pdf";

function Accueil() {
  useEffect(() => {
    const cleanupScrollAnimation = initializeScrollAnimation();

    // Retirez l'écouteur d'événement lorsque le composant est démonté
    return cleanupScrollAnimation;
  });
  return (
    <div>
      <h2>Description</h2>
      <div className="scroll-item">
        Bonjour,
        <br />
        j'ai 25 ans et j'habite à Fréjeville (Tarn). Je suis actuellement en{" "}
        <b>
          recherche d'une entreprise dans le domaine du Développement
          Informatique
        </b>{" "}
        en Occitanie.
        <br />
        Actuellement je suis en capacité de développer en{" "}
        <a
          href="https://fr.wikipedia.org/wiki/HTML5"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-html5 fa-xl html"></i>
        </a>{" "}
        (HTML 5){" "}
        <a
          href="https://fr.wikipedia.org/wiki/Feuilles_de_style_en_cascade"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-css3-alt fa-xl css"></i>
        </a>{" "}
        (Css){" "}
        <a
          href="https://fr.wikipedia.org/wiki/JavaScript"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-js fa-xl js"></i>
        </a>{" "}
        (Javascript). Mais aussi{" "}
        <a href="https://fr.reactjs.org/" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-react fa-xl react"></i>
        </a>{" "}
        (React) que j'utilise actuellement pour mon site web pour la{" "}
        <b>partie Front-End</b>.
        <br />
        Je développe aussi en <b>Back-End</b> avec{" "}
        <a
          href="https://www.php.net/manual/fr/intro-whatis.php"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-php fa-xl"></i>
        </a>{" "}
        (Php), ainsi que son Framework{" "}
        <a href="https://symfony.com/" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-symfony fa-xl symfony"></i>
        </a>{" "}
        (Symfony). J'utilise MySQL pour mes bases de données SQL.
        <br />
        Pour la <b>partie Mobile</b> je me sers de{" "}
        <a href="https://nodejs.org/en/" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-node fa-xl node"></i>
        </a>{" "}
        (NodeJS) / React / Express.
        <br />
        Je me suis exercé aussi pour certaines applications avec une base de
        données NoSQL (MongoDB).
        <br />
        J'ai appris récemment à utiliser le <b>CMS Drupal</b> <i className="fa-brands fa-drupal fa-lg drupal"></i>, pour développer une application pour un promoteur immobilier.
      </div>
      <div>
        <p>
          Télécharger <b>mon CV</b> ici :{" "}
          <a href={CV} download>
            <i className="fa-solid fa-file-pdf fa-xl cv"></i>
          </a>
        </p>
      </div>
    </div>
  );
}

export default Accueil;
