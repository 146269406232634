import React from "react";
import "../css/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-contact">
        <h2>Contactez-moi</h2>
        <p>Email : alary.damien81@gmail.com</p>
        <p>Téléphone : 07 88 42 39 41</p>
      </div>
    </footer>
  );
}

export default Footer;
